body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  src: url('./fonts/HelveticaNeue-Medium.ttf');
  font-family: "helvetica-neue-medium";
}

@font-face {
  src: url('./fonts/Helvetica-Condensed-BlackObl.otf');
  font-family: "helvetica-condensed";
}

@font-face {
  src: url('./fonts/HelveticaNeue.ttf');
  font-family: "helvetica-neue-regular";
}

@font-face {
  src: url("./fonts/Helvetica-Regular.ttf");
  font-family: "helvetica-regular";
}

@font-face {
  src: url("./fonts/HelveticaNeueBold.ttf");
  font-family: "helvetica-neue-bold";
}

@font-face {
  src: url("./fonts/SFProDisplay-Bold.ttf");
  font-family: "sf-pro-display-bold";
}

@font-face {
  src: url("./fonts/SFProText-Regular.ttf");
  font-family: "sf-pro-text-regular";
}

@font-face {
  src: url("./fonts/HelveticaNeue-MediumItalic.otf");
  font-family: "helvetica-neue-medium-italic";
}

@font-face {
  src: url("./fonts/chalkboard-se-regular.ttf");
  font-family: "chalkboard-regular";
}

@font-face {
  font-family: 'FontAwesome';
  src: url('react-native-vector-icons/Fonts/FontAwesome.ttf') format('truetype');
}

@font-face {
  font-family: 'MaterialIcons';
  src: url('react-native-vector-icons/Fonts/MaterialIcons.ttf') format('truetype');
}

@font-face {
  font-family: 'AntDesign';
  src: url('react-native-vector-icons/Fonts/AntDesign.ttf') format('truetype');
}

.menu-option-container {
  height: 120px;
  font-family: 'helvetica-regular';
  color: '#333'
}

.dropdown-container {
  flex: 1;
}

.dropdown-control {
  height: 100%;
}